import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Collapse.css';

// TODO: Remove and use collapseV2
class Collapse extends React.Component {
    renderToggleButton() {
        const { active, onTriggerClick } = this.props;

        return (
            <button
                type="button"
                className={classnames('ltn-de-collapse-trigger', { 'ltn-de-collapse-trigger-active': active })}
                onClick={onTriggerClick}
            >
                <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" fill="none">
                        <g transform="translate(-424.000000, -324.000000)">
                            <g transform="translate(0.000000, 290.000000)">
                                <g transform="translate(225.000000, 35.000000)">
                                    <g transform="translate(217.000000, 17.000000) rotate(90.000000) translate(-217.000000, -17.000000) translate(200.000000, 0.000000)">
                                        <g transform="translate(14.000000, 10.000000)" fill="#697681">
                                            <rect
                                                transform="translate(4.000000, 9.449747) rotate(-135.000000) translate(-4.000000, -9.449747) "
                                                x="3"
                                                y="4.94974747"
                                                width="2"
                                                height="9"
                                                rx="1"
                                            />
                                            <rect
                                                transform="translate(4.000000, 4.500000) rotate(-45.000000) translate(-4.000000, -4.500000) "
                                                x="3"
                                                y="0"
                                                width="2"
                                                height="9"
                                                rx="1"
                                            />
                                        </g>
                                        <circle stroke="#697681" opacity="0.200000003" cx="17" cy="17" r="17" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>
        );
    }

    render() {
        const { label, children, active } = this.props;

        return (
            <section className="ltn-de-collapse">
                <div className="ltn-de-collapse-header">
                    <span className="ltn-de-collapse-label">{label}</span>
                    {this.renderToggleButton()}
                </div>
                <div className={classnames('ltn-de-collapse-content', { 'ltn-de-collapse-active': active })}>
                    {children}
                </div>
            </section>
        );
    }
}

Collapse.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    active: PropTypes.bool,
    onTriggerClick: PropTypes.func,
    children: PropTypes.node
};

Collapse.defaultProps = {
    label: '',
    active: false,
    onTriggerClick: f => f,
    children: null
};

export default Collapse;
