import React from 'react';
import PropTypes from 'prop-types';
import Collapse from '../../__atoms__/Collapse';

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            posActive: props.posActive
        };

        this.setPosActive = this.setPosActive.bind(this);
    }

    setPosActive(pos) {
        this.setState(state => ({ ...state, posActive: state.posActive === pos ? undefined : pos }));
    }

    render() {
        const { collapses, className } = this.props;
        const { posActive } = this.state;

        return (
            <div className={className}>
                {collapses.map((collapse, i) => (
                    <Collapse
                        key={collapse.id}
                        label={collapse.label}
                        active={posActive === i}
                        onTriggerClick={() => this.setPosActive(i)}
                    >
                        {collapse.children}
                    </Collapse>
                ))}
            </div>
        );
    }
}

Accordion.propTypes = {
    className: PropTypes.string,
    posActive: PropTypes.number,
    collapses: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            children: PropTypes.node
        })
    )
};

Accordion.defaultProps = {
    className: '',
    posActive: undefined,
    collapses: []
};

export default Accordion;
