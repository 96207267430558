import get from 'lodash/get';

import {
    APP_MODES,
    DEFAULT_CONSUMPTION,
    DEFAULT_MARKETPLACE_ID,
    DEFAULT_ZIP,
    PAGE_ID,
    SIGN_UP_FLOW
} from '../../../constants';

import { getProducersPageContent } from '../../content';
import { getProducers } from '../../api';
import { calcMonthPriceWithoutFormatting, calculateProducerSavings } from '../prices';
import { constructProducerDetailsUrl, constructSignupUrl } from '../url/urlBuilder';
import CommonPageHelpers from './common';

/**
 * Should get and return common pages content + the specific page content
 * @param {Object} query - Query parameters data
 * @returns {Promise} - { ...content, producersContent, isVersion2Mode }
 */
async function getProducerPageContent(query = {}) {
    const content = await CommonPageHelpers.getCommonPageContent(query);
    const producersPageContent = await getProducersPageContent();

    // FIXME: The same logic on producer-details page, need to move this in the getCommonPageContent with isVersion2Mode (D)
    const tariffFormMode = get(content, 'landingContent.tariffCalculationForm.fields.formMode');
    const isVersion2Mode = tariffFormMode === APP_MODES.V2;

    const isZipOrConsumptionEmpty = !query.zip || !query.consumption;
    const isZipAndConsumptionDefault = query.zip === DEFAULT_ZIP && query.consumption === DEFAULT_CONSUMPTION;
    const isAddressDataEmpty = !query.streetName || !query.houseNumber;
    const shouldOpenTariffCalculatorByDefault = // TODO: Add E2E tests [M] (R)
        isVersion2Mode && isAddressDataEmpty && (isZipOrConsumptionEmpty || isZipAndConsumptionDefault);

    // FIXME: Use producersPageContent from landingContent.producersContent and remove pageContent: producersContent @Anton
    return {
        ...content,
        pageContent: producersPageContent,
        isVersion2Mode,
        shouldOpenTariffCalculatorByDefault
    };
}

async function getProducerPageData(params = {}) {
    // TODO: Check selectedEnergyType only for electricity or both need call this
    if (params.workingPrice) {
        return {
            producers: await ProducersPageHelpers.getProducersWithSavingAndProductData(params, {
                workingPrice: params.workingPrice,
                yearlySavings: params.yearlySavings,
                monthlySavings: params.monthlySavings,
                monthlyBasePrice: params.monthlyBasePrice,
                initialDuration: params.tariff?.initialDuration,
                guaranteePricePeriod: params.tariff?.guaranteePricePeriod
            })
        };
    }
    return {};
}

// The method also uses for contract power plant tariffs (/contract/index.js).
// TODO: Need to separate or move specific logic, because we override some data in the /contract/index.js. @Anton
async function getProducersWithSavingAndProductData(params, productData) {
    const { content: { landingDetails: { config = {} } = {} } = {} } = params;

    const producers = await getProducers(
        {
            workingPrice: productData.workingPrice,
            workingPriceNT: productData.workingPriceNT
        },
        {
            ...params.query,
            marketplaceId: params?.content?.landingDetails?.marketplaceId || DEFAULT_MARKETPLACE_ID
        }
    );

    const producersWithSavingsAndLinks = producers.map(producer => {
        const { yearlySavings, monthlySavings } = calculateProducerSavings({
            usage: params.query.consumption || DEFAULT_CONSUMPTION,
            yearlySavings: productData.yearlySavings,
            monthlySavings: productData.monthlySavings,
            producer
        });

        const producerDetailsUrl = constructProducerDetailsUrl(
            { ...params.query, producerId: producer.id },
            producer.name
        );
        const signUpUrl = config.overriddenSignupUrl ?
            config.overriddenSignupUrl :
            constructSignupUrl({
                signupFlow: SIGN_UP_FLOW.STANDARD,
                ...params.query,
                identifier: PAGE_ID.STANDARD,
                producerId: producer.id
            });
        const generalMonthlyPrice = calcMonthPriceWithoutFormatting(
            producer.price,
            productData.monthlyBasePrice,
            params.query.consumption || DEFAULT_CONSUMPTION
        );

        return {
            ...producer,
            yearlySavings,
            monthlySavings,
            generalMonthlyPrice,
            producerDetailsUrl,
            signUpUrl,
            productData
        };
    });

    return producersWithSavingsAndLinks;
}

const ProducersPageHelpers = {
    getProducerPageContent,
    getProducerPageData,
    getProducersWithSavingAndProductData
};

export default ProducersPageHelpers;
