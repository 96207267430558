import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ProducerFilter, ProducerSortFilter, ModalWindow } from 'lition-shared-components';

import { Triangle } from '../../__icons__';

import { PRODUCER_FILTER_OPTIONS, PRODUCER_ORDER_BY_OPTIONS } from '../../../constants';
import './TariffCalculatorPopup.css';

// TODO: We need to refactor this component.
// Remove the ProducerFilter and ProducerSortFilter component from this and use as children from the producer page.
// Rename to more abstract name
export default function TariffCalculatorPopup({
    onProducerTypeChange,
    labels: {
        indicatedLocation: titleForIndicatedLocation,
        noIndicatedLocation: titleForNoIndicatedLocation,
        filterCaption,
        sortFilterCaption
    },
    selectedProducerTypes,
    isOpen: isOpenProp,
    onPopupClose,
    producerFilterOptions,
    children,
    onSelectOrderByOption,
    orderBy,
    shouldOpenTariffCalculatorByDefault
}) {
    const timeout = useRef();

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        if (shouldOpenTariffCalculatorByDefault && !isOpenProp) {
            timeout.current = setTimeout(() => {
                setOpen(true);
            }, 0);
        }

        return () => {
            clearTimeout(timeout.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOpen(isOpenProp);
        if (isOpenProp) {
            clearTimeout(timeout.current);
        }
    }, [isOpenProp]);

    const closePopup = () => {
        if (onPopupClose) {
            onPopupClose();
        }
        setOpen(false);
    };

    return (
        <ModalWindow
            className="ltn-de-site-tariff-popup__modal"
            isOpen={isOpen}
            onModalClose={closePopup}
            style={{ minHeight: 'calc(var(--frame-height, 100vh) - 64px)' }}
        >
            <div className="ltn-de-site-tariff-popup">
                <h4
                    className="ltn-de-site-tariff-popup__header"
                    dangerouslySetInnerHTML={{
                        __html: shouldOpenTariffCalculatorByDefault ?
                            titleForNoIndicatedLocation :
                            titleForIndicatedLocation
                    }}
                />
                {children}
                <div className="ltn-de-site-tariff-popup__filters-block">
                    <div className="ltn-de-site-tariff-popup__filter">
                        <h3 className="ltn-de-site-tariff-popup__subheader">{`${filterCaption} `}</h3>
                        <ProducerFilter
                            selectedProducerTypes={selectedProducerTypes}
                            onTypeChange={onProducerTypeChange}
                            options={producerFilterOptions}
                        />
                    </div>
                    <div className="ltn-de-site-tariff-popup__filter">
                        <h3 className="ltn-de-site-tariff-popup__subheader">{sortFilterCaption}</h3>
                        <ProducerSortFilter
                            labels={{}} // TODO: refactor ProducerSortFilter to remove this prop (set as default or other solution) @Anton
                            orderBy={orderBy}
                            onClick={onSelectOrderByOption}
                            withAscDescOptions={false}
                            orderButtonIcon={<Triangle />}
                            orderByOptions={{
                                price: PRODUCER_ORDER_BY_OPTIONS.price,
                                distance: PRODUCER_ORDER_BY_OPTIONS.distance
                            }}
                        />
                    </div>
                </div>
            </div>
        </ModalWindow>
    );
}

TariffCalculatorPopup.propTypes = {
    onProducerTypeChange: PropTypes.func.isRequired,
    onPopupClose: PropTypes.func,
    labels: PropTypes.shape({
        indicatedLocation: PropTypes.string,
        noIndicatedLocation: PropTypes.string,
        filterCaption: PropTypes.string,
        sortFilterCaption: PropTypes.string
    }),
    selectedProducerTypes: PropTypes.arrayOf(PropTypes.string),
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    producerFilterOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string
        })
    ),
    onSelectOrderByOption: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    shouldOpenTariffCalculatorByDefault: PropTypes.bool
};

TariffCalculatorPopup.defaultProps = {
    onPopupClose: undefined,
    labels: {
        indicatedLocation: 'Deine Angaben:',
        noIndicatedLocation: 'Für ein persönliches Angebot<br> gib bitte hier deine Angaben ein:',
        filterCaption: 'Energiequellen:',
        sortFilterCaption: 'Sortierung:'
    },
    selectedProducerTypes: undefined,
    isOpen: false,
    producerFilterOptions: PRODUCER_FILTER_OPTIONS,
    children: null,
    shouldOpenTariffCalculatorByDefault: false
};
